/**
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 */

/* global _VALOTA_TESTING_MAX, _VALOTA_DEVICE, ValotaAndroid, ValotaEngine, _VALOTA_EXTENSION, _VALOTA_REST, _applicationData, _userName, Storage, _STATIC_CONTENT, _NO_FIRST_FETCH, _STATIC_SERVE, FLOW_SLOWNESS, _VALOTA_TIZEN, _VALOTA_HOME */

if (typeof _PREVIEW === 'undefined') {
	_PREVIEW = false;
}

var _PLAY_HISTORY_ENABLED = false;
var _LOCALSTORAGE_PREFIX = 'valota_';
var _SSO_TYPE = "none";
var _SSO_URL = null;
var _SSO_USE_BUTTON = false;
var _LOGGED_IN = false;
var _SSO_URL = "";
var _SSO_AZURE = {oid: "", idp: "", session: ""};
var currentStory = -1; // current story index
var _overlayApp = null;
var _displayID = null;
if (_PREVIEW) {
	_displayID = 'PREVIEW';
}

var _DISPLAY_UPDATED = 0;
var _SHOW_TITLE = null;
var _SHOW_LOGO = null;
var _SHOW_STATS = null;
var _SHOW_NEXT = null;
var _SHOW_TITLE_CLOCK = false;
var _SHOW_STATS_CLOCK = false;

var _TIME_CHECK = 0;
var _CUR_CLOCK = '00:00';

var _READY_CALLBACK = null;

var _viewDistance = 1.5;

var _LAST_TIMER_EPOCH_S = Date.now() / 1000;
var _SCRIPT_START_EPOCH_MS = Date.now();
var _REQUEST_TIMER = null;

var _VALOTA_CUR_BG = 0;

var _FLOW_HANDLER = null;

var _DEFAULT_RUN_TIME = 10; // How many seconds a app runs by default
var _DEFAULT_CYCLE_TIME = 10; // How many seconds one app cycle runs by default

var _DEFAULT_CYCLES = 3; // How many cycles app tries to run by default

var _MAX_RUN_TIME = 2 * 60 * 60; // Maximum run time ( two hours )
var _MAX_CYCLE_TIME = 2 * 60 * 60; // Maximum cycle time ( two hours )

var _MAX_CYCLES = 40; // max cycles

var _SCREENSHOT_INTERVAL_S = 60 * 60 * 12; // screenshot interval for apps, should be quite a high number (once a day or so)

var _TRANSITION_TIME = 300; // Transition times in ms

var _DISPLAY_ONLINE = new Date();

var _APP_ERROR_CHECK_FREQUENCE = 60000; // milliseconds
var _APP_INACTIVE_TICKS_TO_RELOAD = 5; // 5 minutes of inactivity without a reason causes a reload
var _APP_INACTIVE_TICKS_TO_RELOAD_ALL_OK = 60; // 60 minutes of inactivity even with a reason causes a reload
var _ENGINE_ERROR = false; // engine error causes a reload
var _ENGINE_ERROR_ROUNDS = 0;

var _HISTORY_TRACK = true;
var _HISTORY_INTERVAL_S = 1000 * 60 * 2; // two minutes to simulate ticks
var _HISTORY = {};


var _NOTIFICATIONS = []; // notifications for engine to show
/*
 {
 app_uuid:
 title:
 icon:
 name:
 msg:
 show_until:
 }
 */

/*
 * These values are are defined by the server, so they are commented out here
 var _VALOTA_REST = 'http://localhost/valota_rest/';
 var _DATA_REFRESH_TIME = 10; // seconds
 */

//Polyfill for some older browsers
Math.log2 = Math.log2 || function (x) {
	return Math.log(x) * Math.LOG2E;
};

if (!Date.now) {
	Date.now = function () {
		return new Date().getTime();
	};
}

function setLocal(name, value) {
	if (staticServe() && name === 'application_data') {
		return;
	}

	if (_PREVIEW) {
		return;
	}

	if (typeof value === 'object' || typeof value === 'array') {
		value = JSON.stringify(value);
	}
	localStorage.setItem(_LOCALSTORAGE_PREFIX + name, value);
}

function getLocal(name, def) {

	if (_PREVIEW) {
		return;
	}
	var item;
	var origItem = localStorage.getItem(_LOCALSTORAGE_PREFIX + name);

	if (origItem === null) {
		if (typeof (def) !== 'undefined') {
			return def;
		}
		return null;
	}

	try {
		item = JSON.parse(origItem);
	} catch (error) {
		item = origItem;
	}

	return item;
}

function saveUUID() {
	var fs = null;
	fs = ValotaEngine.FileSystem;
	fs.saveUUID(_displayID);
}

function clearUUID() {
	var fs = null;
	if (typeof _VALOTA_DEVICE !== 'undefined') {
		fs = ValotaEngine.ChromeOS;
	} else if (typeof _VALOTA_EXTENSION !== 'undefined') {
		fs = ValotaEngine.ChromeExtension;
	} else if (typeof _VALOTA_TIZEN !== 'undefined') {
		fs = ValotaEngine.Tizen;
	} else if (typeof ValotaAndroid !== 'undefined') {
		fs = ValotaEngine.Android;
	} else {
		fs = ValotaEngine.FileSystem;
	}
	fs.clearUUID();
}

// also used in extension
function makeId(length) {
	var possibleFirst = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var possible = possibleFirst + "!#%&/()=?+-_^§½<>[]{}$";

	var text = possibleFirst.charAt(Math.floor(Math.random() * possibleFirst.length));

	for (var i = 1; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}


/**
 * Simplified and unified gettext+sprintf function for javascript
 *
 * @returns {String}
 */
function _() {
// get the arguments
	var args = [];
	Array.prototype.push.apply(args, arguments);
	//if we have no arguments return an empty string
	if (args.length === 0) {
		return '';
	}

	var text = args[0];
	var loc = text.search("%s");
	var num = 1;
	while (loc !== -1) {
		var replacement = '';
		if (typeof args[num] !== 'undefined') {
			replacement = args[num];
		}

		text = text.replace("%s", replacement);
		loc = text.search("%s");
		++num;
	}

	return text;
}

/**
 * Test whether variable is defined
 *
 * @param {*} test variable to test
 * @returns {boolean} true if variable is defined
 */
function isset(test) {
	if (typeof (test) === 'undefined')
		return false;
	return true;
}

/**
 * Check whether variable is empty
 *
 * @param {*} test variable to test
 * @returns {boolean} true if variable is not empty
 */
function isEmpty(test) {
	if (typeof (test) === 'undefined' || typeof (test) === null || !test)
		return true;
	return false;
}


/**
 * Change time to seconds
 *
 * @param {number} hours
 * @param {number} mins
 * @param {number} secs
 * @returns {number}
 */
function timeToSecs(hours, mins, secs) {
	var ret = 0;
	ret += parseInt(hours) * 60;
	if (isset(mins)) {
		ret += parseInt(mins);
	}

	ret *= 60;
	if (isset(secs)) {
		ret += parseInt(secs);
	}


	return ret;
}


if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function (elt /*, from*/) {
		var len = this.length >>> 0;
		var from = Number(arguments[1]) || 0;
		from = (from < 0) ? Math.ceil(from) : Math.floor(from);
		if (from < 0)
			from += len;
		for (; from < len; from++) {
			if (from in this &&
				this[from] === elt)
				return from;
		}
		return -1;
	};
}


function logError(e) {
	console.error(e);
}

function init() {
	window.onerror = function (event, source, lineno, colno, error) {
		_ENGINE_ERROR = true;
		logError("start of " + source + "(" + lineno + "/" + colno + "): " + (typeof event !== "undefined" ? event.toString() : "undefined") + " " + (typeof error !== "undefined" ? error.toString() : "undefined"));
		return false;
	};


	if (!checkCompatibility()) {
		// browser / framework doesn't support our technologies
		var errorDiv = document.createElement('div');
		errorDiv.className = 'error_cont';
		var error = document.createElement('div');
		error.className = 'error';
		error.innerHTML = _('Unfortunately your current browser version doesn\'t \n\
	seem to support enough of the HTML5 standard for our engine.');
		errorDiv.appendChild(error);
		document.getElementById('valota_container').innerHTML = '';
		document.getElementById('valota_container').appendChild(errorDiv);

		return;
	}


	var params = window.location.search.substring(1).split("&").reduce((previous, current) => {
		const [key, value] = current.split("=");
		previous[key] = value;
		return previous
	}, {});

	if (!params.hasOwnProperty('id')) {
		console.error("no id");
		return;
	}
	_displayID = params['id'];
	_LOCALSTORAGE_PREFIX = "valota_" + _displayID + "_";
	loadSavedData();

	chooseStage(true);

	var style = document.createElement('style');
	style.type = 'text/css';
	style.setAttribute('id', 'valota_main_css');

	var fs = null;
	fs = ValotaEngine.FileSystem;
	fs.start();

	setInterval(errorCheck, _APP_ERROR_CHECK_FREQUENCE);

	_LOG_START = Date.now() / 1000;

	if (_RESIZE_TIMEOUT) {
		clearTimeout(_RESIZE_TIMEOUT);
		_RESIZE_TIMEOUT = null;
	}

}

var _REPORTED_WIDTH = null;
var _REPORTED_HEIGHT = null;

function displayOff() {
	if (false) {
		document.getElementById('owner_logo_off').style.backgroundImage = "url(" + _ownerLogo + ")";
	} else {
		document.getElementById('owner_logo_off').style.backgroundImage = "url(/img/valotalive_logo_2019.svg)";
	}
	document.getElementById('display_name_off').innerHTML = (_displayName ? _displayName : "");
	document.getElementById('display_off').style.display = 'flex';
}

function displayOn() {
	document.getElementById('display_off').style.display = 'none';
}

var _RESIZE_TIMEOUT = null;

window.onresize = function () {
};

window.onunload = function () {
	if (Date.now() - _SCRIPT_START_EPOCH_MS > 10 * 60 * 1000) { // save log only if app has run 10mins
		// save to summary
		saveLog();
	}

	if (_HISTORY_TRACK) {
		saveHistory();
	}

};


function getYMDDate(d) {
	return d.getUTCFullYear() * 10000 + ((d.getUTCMonth() + 1) * 100) + d.getUTCDate();
}

function saveHistory() {

	let curTime = new Date(Date.now() + _TIME_CHECK);

	let today = getYMDDate(curTime);


	if (!(today in _HISTORY)) {
		_HISTORY[today] = {};
	}




	if (Array.isArray(_applicationData)) {
		for (var i = 0; i < _applicationData.length; ++i) {
			if (!(_applicationData[i].uuid in _HISTORY[today])) {
				_HISTORY[today][_applicationData[i].uuid] = 1;
			} else {
				++_HISTORY[today][_applicationData[i].uuid];
			}
		}
	}


	setLocal('history', JSON.stringify(_HISTORY));

}

var _LOG_START, _LOG_END;
var _UNSENT_LOGS = [];

/**
 * Clears the local storage for the _LOCALSTORAGE_PREFIX, but retains everything else
 */
Storage.prototype._clear = Storage.prototype.clear;
Storage.prototype.clear = function () {
	for (var i = localStorage.length - 1; i >= 0; i--) {
		if (localStorage.key(i).startsWith(_LOCALSTORAGE_PREFIX)) {
			localStorage.removeItem(localStorage.key(i));
		}
	}
};

/**
 * Check if engine's applications have errors and reload them if they have
 *
 */
function errorCheck() {
	if (typeof _VALOTA_TESTING_MAX !== 'undefined') {
		return;
	}
	//TODO: Make sure engine or apps don't go into a reload loop!!

	var time_epoch_s = Date.now() / 1000;
	// Engine errors cause an instant reload and clearing of application cache
	if (_ENGINE_ERROR === true) {
		localStorage.clear();
		location.reload(true);
	}

	var one_is_ready = false;
	for (var i in _applicationData) {
		//Should we reload the app?
		var reload_app = false;
		// check if the app is not ready?
		if (_applicationData[i].ready === false) {
			// add to inactivity
			++_applicationData[i].inactiveFor;

		} else {
			if (_overlayApp !== _applicationData[i].uuid) {
				one_is_ready = true;
			}
			// reset inactivity counter
			_applicationData[i].inactiveFor = 0;
		}

		// if app has been inactive longer then inactivity limit
		if (_applicationData[i].inactiveFor > _APP_INACTIVE_TICKS_TO_RELOAD) {

			// ask if all is ok with the app
			var all_ok = runFunction(_applicationData[i].container.contentWindow.ValotaAllOk);
			if (!all_ok) {
				// all is not ok so lets reload
				reload_app = true;
			} else {
				// all is ok, but has it been ok for too long??
				//if (_applicationData[i].inactiveFor > _APP_INACTIVE_TICKS_TO_RELOAD_ALL_OK) {
				//	reload_app = true;
				//}
			}
		}


		// has app reported errors since last error check
		if (_applicationData[i].errorsSinceCheck > 0) {
			// reload the app if there are errors
			reload_app = true;
		}


		//Do we still want to reload app?
		if (reload_app) {


			var in_5 = time_epoch_s - 300;

			if (_applicationData[i].reloads.length > 0) {

				// have we loaded this app within 5 mins already
				if (_applicationData[i].reloads[0] > in_5) {
					reload_app = false;
				}


			}
			if (reload_app) {


				//reset error numbers
				_applicationData[i].errorsSinceCheck = 0;
				_applicationData[i].cleanErrorChecks = 0;
				_applicationData[i].inactiveFor = 0;

				//add new error time
				_applicationData[i].reloads.unshift(Date.now() / 1000);

				// Every second reload should be a hard reload
				var hard_reload = _applicationData[i].reloads.length % 2 === 0 ? true : false;

				// app is not ready
				_applicationData[i].ready = false;
				try {
					ValotaEngine.Video.stopVideosByApp(_applicationData[i].uuid);
				} catch (e) {
					logError("reload app video stop errored " + e.toString(), _applicationData[i].uuid, 'warning');
				}

				console.log('[Engine] Reloading app ' + _applicationData[i].name);
				if (_overlayApp === _applicationData[i].uuid) {
					document.getElementById('overlay').removeChild(_applicationData[i].container);
				} else {
					document.getElementById('valota_app_container').removeChild(_applicationData[i].container);
				}
				//reload app iframe and hope for the best
				var frame = document.createElement('iframe');
				frame.src = _applicationData[i].url;
				frame.sandbox = 'allow-scripts allow-same-origin allow-forms';
				frame.valotaAppId = _applicationData[i].uuid;
				frame.className = 'inactive';
				if (_overlayApp === _applicationData[i].uuid) {
					document.getElementById('overlay').appendChild(frame);
				} else {
					document.getElementById('valota_app_container').appendChild(frame);
				}
				if (!_PREVIEW) {
					frame.contentWindow.onerror = function (f) {
						return function (event, source, lineno, colno, error) {

							errorOn(f.valotaAppId);

							logError("start of " + source + "(" + lineno + "/" + colno + "): " + (typeof event !== 'undefined' ? event.toString() : "undefined") + " " + (typeof error !== 'undefined' ? error.toString() : "undefined"), f.valotaAppId);

							return false;
						};
					}(frame);

				}
				_applicationData[i].container = frame;
			} else {
				console.log('[Engine] App ' + _applicationData[i].name + ' needed reloading but was reloaded too recently');
			}

		} else {
			++_applicationData[i].cleanErrorChecks;
		}
	}

	//at least one is ready, why isn't valota_container the current window??
	if (one_is_ready && _CURRENT_WINDOW !== 'valota_container') {
		//five error rounds same things, reload engine
		if (_ENGINE_ERROR_ROUNDS >= 5) {
			localStorage.clear();
			location.reload(true);
		}
		++_ENGINE_ERROR_ROUNDS;
	} else {
		_ENGINE_ERROR_ROUNDS = 0;
	}

}

function chooseStage(init) {


	// we have some data, lets attempt to crunch it
	if (typeof _applicationData !== 'undefined') {
		loadApplicationData();
	}

	// resize engine
	engineLayout();

	waitForContent(init);


}

function waitForContent(init, dontFetch) {
	if (typeof init === 'undefined') {
		init = false;
	}

	if (typeof dontFetch === 'undefined') {
		dontFetch = false;
	}

	if (_ownerLogo) {
		//document.getElementById('owner_logo').style.backgroundImage = "url(" + _ownerLogo + ")";
	}
	document.getElementById('display_name').innerHTML = (_displayName ? _displayName : "");

	if (init) {
		// lets add a one second delay for the first time we start waiting for content
		setTimeout(function () {
			// but only if current window is "working", we don't want to hide more important windows
			if (_CURRENT_WINDOW === 'working') {
				showWindow('waiting_for_content');
			}
		}, 1000);
	} else {
		showWindow('waiting_for_content');
	}
	if (!dontFetch) {
		fetchData();
	}
}

function cacheFonts(num) {
	cacheFont(num, 'bodyFont');
	cacheFont(num, 'titleFont');
}

function cacheFont(num, which) {
	if (isEmpty(_applicationData[num].palette) || isEmpty(_applicationData[num].palette.palette) || isEmpty(_applicationData[num].palette.palette[which])) {
		return;
	}
	$.getJSON(_VALOTA_REST + "?action=valota_api/fonts/get_font_css&font=" + _applicationData[num].palette.palette[which], function (data) {
		if (!data.hasOwnProperty('response')) {
			console.warn("loading font failed " + _applicationData[num].palette.palette[which] + ": " + data);
			return;
		}
		if (data.response.data.css !== "") {
			// inject this css to the main document
			if (document.getElementById('valota_font_' + _applicationData[num].palette.palette[which] + '_css') !== null) {
				return;
			}
			var style = document.createElement('style');
			style.type = 'text/css';
			style.setAttribute('id', 'valota_font_' + _applicationData[num].palette.palette[which] + '_css');
			if (style.styleSheet) {
				style.styleSheet.cssText = data.response.data.css;
			} else {
				style.innerHTML = data.response.data.css;
			}
			document.getElementsByTagName('head')[0].appendChild(style);
		}
	}).fail(function (data) {
		console.warn("loading font failed " + _applicationData[num].palette.palette[which] + ": " + data);
	});
}

function runPendingFunctions() {
	for (var i = 0; i < _applicationData.length; ++i) {
		for (var j = 0; j < _applicationData[i].changesPending.length; j++) {
			runFunction(_applicationData[i].container.contentWindow[_applicationData[i].changesPending[j]], _applicationData[i].uuid);
		}
		_applicationData[i].changesPending = [];
	}
}

function loadFonts(num, callback) {
	var n = 2;

	var temp = function (index) {
		if (isEmpty(_applicationData[num].palette) || isEmpty(_applicationData[num].palette.palette) || isEmpty(_applicationData[num].palette.palette[index])) {
			n--;
			if (n === 0) {
				callback(num);
			}
			return;
		}
		if (_applicationData[num].container.contentDocument.getElementById('valota_font_' + _applicationData[num].palette.palette[index] + '_css') !== null) {
			n--;
			if (n === 0) {
				callback(num);
			}
			return;
		}
		$.getJSON(_VALOTA_REST + "?action=valota_api/fonts/get_font_css&font=" + _applicationData[num].palette.palette[index], function (data) {
			if (!data.hasOwnProperty('response')) {
				console.warn("loading font failed " + _applicationData[num].palette.palette[index] + ": " + data);
				n--;
				if (n === 0) {
					callback(num);
				}
				return;
			}
			if (data.response.data.css !== "") {
				// inject this css to the iframe
				var style = document.createElement('style');
				style.type = 'text/css';
				style.setAttribute('id', 'valota_font_' + _applicationData[num].palette.palette[index] + '_css');
				if (style.styleSheet) {
					style.styleSheet.cssText = data.response.data.css;
				} else {
					style.innerHTML = data.response.data.css;
				}
				_applicationData[num].container.contentDocument.getElementsByTagName('head')[0].appendChild(style);
			}
			n--;
			if (n === 0) {
				callback(num);
			}
		}).fail(function (data) {
			console.warn("loading font failed " + _applicationData[num].palette.palette[index] + ": " + data);
			n--;
			if (n === 0) {
				callback(num);
			}
		});
	};
	temp('bodyFont');
	temp('titleFont');
}

function loadApplicationData() {
	for (var i = 0; i < _applicationData.length; ++i) {
		cacheFonts(i);
		loadApplication(i);
	}
}

function loadApplication(num) {
	_applicationData[num].ready = false;
	_applicationData[num].latestContent = -1;
	_applicationData[num].changesPending = [];
	if (_applicationData[num].uuid === _overlayApp && typeof _VALOTA_DEVICE !== 'undefined') {
		_applicationData[num].isOverlay = true;
		ValotaEngine.ChromeOS.playOverlayApp(_applicationData[num]);
		_applicationData[num].inactiveFor = NaN; // set to NaN to not reload from here
		return;
	}
//errors variables
	_applicationData[num].errorsSinceCheck = 0;
	_applicationData[num].cleanErrorChecks = 0;
	_applicationData[num].reloads = [];
	_applicationData[num].inactiveFor = 0;

	_applicationData[num].playNext = null;
	if (typeof (_applicationData[num].runTime) === 'undefined') {
		_applicationData[num].runTime = _DEFAULT_RUN_TIME;
	}
	if (typeof (_applicationData[num].cycleTime) === 'undefined') {
		_applicationData[num].cycleTime = _DEFAULT_CYCLE_TIME;
	}

	if (typeof (_applicationData[num].preferredCycles) === 'undefined') {
		_applicationData[num].preferredCycles = _DEFAULT_CYCLES;
	}
	var frame = document.createElement('iframe');
	frame.src = _applicationData[num].url;
	frame.sandbox = 'allow-scripts allow-same-origin allow-forms';
	frame.valotaAppId = _applicationData[num].uuid;
	frame.className = 'inactive';
	if (_applicationData[num].uuid === _overlayApp) {
		document.getElementById('overlay').appendChild(frame);
		_applicationData[num].isOverlay = true;
	} else {
		document.getElementById('valota_app_container').appendChild(frame);
	}
	if (!_PREVIEW) {
		frame.contentWindow.onerror = function (event, source, lineno, colno, error) {

			errorOn(frame.valotaAppId);

			logError("start of " + source + "(" + lineno + "/" + colno + "): " + (typeof event !== 'undefined' ? event.toString() : "undefined") + " " + (typeof error !== 'undefined' ? error.toString() : "undefined"), frame.valotaAppId);

			return false;
		};
		frame.contentWindow.onkeydown = handleKey;
	}

	_applicationData[num].container = frame;

	preloadBgs(num);
}

function errorOn(appUUID) {
	console.warn("[Engine] error in", appUUID);
	var num = getStoryLoc(appUUID);

	if (num === -1) {
		return;
	}
	console.warn("[Engine] error in meaning", num);

	++_applicationData[num].errorsSinceCheck;
	_applicationData[num].ready = false;
	try {
		ValotaEngine.Video.stopVideosByApp(appUUID);
	} catch (e) {
		logError("errorOn video stop errored " + e.toString(), appUUID, 'warning');
	}
	_applicationData[num].notReadyCalled = true;
	if (_overlayApp === _applicationData[num].uuid) {
		stopOverlay(num);
	}
	if (currentStory === num) {
		_applicationData[currentStory].container.className = 'inactive';
		currentStory = -1;
		_STARTING_PLAY = false;
		runStories();
	}
}

function unloadApplication(num) {
	removeApplication(num);
}

function removeApplication(num) {
	_applicationData[num].container.parentNode.removeChild(_applicationData[num].container);
	_applicationData.splice(num, 1);
	if (currentStory === num) {
		currentStory = -1;
	}
	if (_flow.config.uuid === 'local') {
		generateLocalFlow();
		reFlow();
	}
	setLocal('application_data', _applicationData);
}

var _REQUEST_APP = null;

/**
 * remove search part from the url and save supported keys to variables
 *
 * @returns {undefined}
 */
function checkRequest() {
	console.log("moi");
	if (location.search) {
		var get_data = location.search.substr(1).split("&");
		if (!staticServe()) {
			history.pushState("", "", location.href.split("?")[0]);
		}
		for (var i = 0; i < get_data.length; ++i) {
			var req = get_data[i].split("=");
			if (req[0] === "u") {
				_REQUEST_APP = req[1];
			}
		}
	}
}


/**
 * Returns whether this display is defined as a static serve display
 *
 * @returns {Boolean}
 */
function staticServe() {
	if (typeof _STATIC_SERVE !== 'undefined' && _STATIC_SERVE) {
		return true;
	}
	return false;
}

/**
 * Load previously saved data from the localStorage
 *
 * @returns {undefined}
 */
function loadSavedData() {

	_displayName = getLocal('display_name');
	_displayUser = getLocal('display_user');
	_viewDistance = getLocal('view_distance');
	_displayDate = getLocal('claim_date');
	_ownerLogo = getLocal('owner_logo');
	_flow = getLocal('flow');
	_DISPLAY_UPDATED = getLocal('display_updated', 0);
	_SHOW_STATS = getLocal('show_stats', false);
	_SHOW_TITLE = getLocal('show_title', false);
	_SHOW_LOGO = getLocal('show_logo', true);
	_SHOW_NEXT = getLocal('show_next', false);
	_SHOW_TITLE_CLOCK = getLocal('show_title_clock', false);
	_SHOW_STATS_CLOCK = getLocal('show_stats_clock', false);
	_overlayApp = getLocal('overlay_app');

	if (typeof _applicationData !== "undefined") {

		setLocal('application_data', _applicationData);

		// we have a single app served for now, reset the flow
		_flow = null;
		setLocal('flow', null);

	} else if (getLocal('application_data') !== null) {
		_applicationData = getLocal('application_data');
	}

	// make sure nothing is ready yet
	if (typeof _applicationData !== "undefined" && _applicationData) {
		for (var i = 0; i < _applicationData.length; ++i) {
			_applicationData[i].ready = false;
		}

	} else {
		_applicationData = [];
	}
}

/**
 * Does the display have apps
 *
 * @returns {Boolean}
 */
function hasApps() {
	if (typeof _applicationData !== "undefined" && _applicationData.length) {
		return true;
	}
	return false;
}

/**
 * Does the display have a flow
 *
 * @returns {Boolean}
 */
function hasFlow() {
	if (typeof _flow !== "undefined" && _flow) {
		return true;
	}
	return false;

}

/**
 *
 * @param {type} deviceId
 * @returns {undefined}
 */
function sendMyDeviceID(deviceId) {
	if (typeof deviceId !== 'string') {
		return;
	}
	var request = {
		action: "valota_api/displays/set_device_id",
		deviceID: deviceId
	};
	request.displayUUID = _displayID;
	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function (data) {
			if (typeof data.response !== 'undefined' && typeof data.response.old_display !== 'undefined' && data.response.old_display) {
				console.warn("[Engine Device ID] new uuid");
				// this player already had a display				
				_displayID = data.response.old_display;
				setLocal('display', _displayID);
				_DISPLAY_UPDATED = null;
				saveUUID();
				fetchData(true);
			}
		},
		error: function (xhr, textStatus, errorThrown) {
			console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
		},
		dataType: "json"
	});
}

/**
 *
 * @param {type} btAddress
 * @returns {undefined}
 */
function sendMyBTAddress(btAddress) {
	if (typeof btAddress === 'undefined' || btAddress === null) {
		return;
	}
	var request = {
		action: "valota_api/displays/set_bt_address",
		btAddress: btAddress
	};
	request.displayUUID = _displayID;
	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function (data) {
			if (typeof data.response !== 'undefined' && typeof data.response.old_display !== 'undefined' && data.response.old_display) {
				// this bt address already used
				console.error("BT conflict");
			}
		},
		error: function (xhr, textStatus, errorThrown) {
			console.warn('Server responded with an error code. ' + textStatus + ' ' + errorThrown, xhr);
		},
		dataType: "json"
	});
}

var _FETCH_FAILS = 0;
var _FETCH_SUCCESFULL = 0;
var _FETCH_FAILS_IN_ROW = 0;
var _FETCH_FAIL_START = null;
var _FETCH_FAILS_REASON = null;

function failedFetch(mes) {
	console.warn(mes);
	if (_FETCH_FAILS_IN_ROW === 0) {
		_FETCH_FAIL_START = new Date();
	}
	_FETCH_FAILS_REASON = mes;
	++_FETCH_FAILS;
	++_FETCH_FAILS_IN_ROW;
	setStatus();
}

function succesfulFetch() {
	_FETCH_FAILS_IN_ROW = 0;
	_FETCH_FAIL_START = null;
	++_FETCH_SUCCESFULL;
	setStatus();

}

function setStatus() {
	if (_FETCH_FAILS_IN_ROW > 0) {
		if (_FETCH_FAILS_IN_ROW > 2) {
			$('#display_stats').toggleClass('error', true);
			$('#display_stats').toggleClass('warning', false);
			$('#display_stats').toggleClass('play', false);
		} else {
			$('#display_stats').toggleClass('error', false);
			$('#display_stats').toggleClass('warning', true);
			$('#display_stats').toggleClass('play', false);
		}


		$('#stats_offline_reason').toggleClass('hidden', false);

		$('#stats_offline_reason').html(_FETCH_FAILS_REASON);

	} else {
		$('#display_stats').toggleClass('warning', false);
		$('#display_stats').toggleClass('error', false);
		$('#display_stats').toggleClass('play', true);
		$('#stats_offline_reason').toggleClass('hidden', true);
	}

	formatSmallStats();

}

/**
 * Generate / refresh the flow of apps
 */
function reFlow() {
	if (_FLOW_TIMEOUT) {
		console.log('[Engine] remove flow interval');
		clearInterval(_FLOW_TIMEOUT);
		_FLOW_TIMEOUT = null;
	}

	if (_FLOW_HANDLER === null || _FLOW_HANDLER._uuid !== _flow.config.uuid) {
		// our flow is totally new
		if (_FLOW_HANDLER) {
			saveLog();
		}
		ValotaEngine.Video.stopAllVideos();
		_FLOW_HANDLER = new ValotaEngine.FlowHandler(_flow.config);
		_FLOW_HANDLER.loadContents(_flow.contents);
	} else {
		// old flow...
		// update flow only if update id changes
		if (_flow.config.updateId !== _FLOW_HANDLER._updateId) {
			saveLog();

			_FLOW_HANDLER._name = _flow.config.name;
			_FLOW_HANDLER._updateId = _flow.config.updateId;
			_FLOW_HANDLER.loadContents(_flow.contents);
		}
	}

	if (isset(_RUN_TIMEOUT)) {
		console.log('[Engine] remove app run interval');
		clearInterval(_RUN_TIMEOUT);
		_RUN_TIMEOUT = null;

	}

	runFlow();
}

var _FLOW_TIMEOUT;

function runFlow() {
	if (_FLOW_HANDLER) {
		_FLOW_HANDLER.tick();
		if (!_FLOW_TIMEOUT) {
			console.log('[FLOW] create flow interval ' + FLOW_SLOWNESS);
			_FLOW_TIMEOUT = setInterval(runFlow, FLOW_SLOWNESS * 1000);
		}
	} else {
		if (isset(_FLOW_TIMEOUT)) {
			console.log('[FLOW] remove flow interval');
			clearInterval(_FLOW_TIMEOUT);
			_FLOW_TIMEOUT = null;
		}
	}
}

function setDisplayName(name) {
	setLocal('display_name', name);
	_displayName = name;
	document.getElementById('display_name').innerHTML = name;
}

function setDisplayUser(name) {
	setLocal('display_user', name);
	_displayUser = name;
	document.getElementById('stats_display_user').innerHTML = name ? ' [' + name + '] ' : '';
	if (name) {
		$('#stats_display_user').removeClass('hide');
	} else {
		$('#stats_display_user').addClass('hide');
	}
}

function setOwnerLogo(logo) {

	setLocal('owner_logo', logo);
	_ownerLogo = logo;

	if (logo) {
		document.getElementById('owner_logo').style.backgroundImage = "url(" + _ownerLogo + ")";
	} else {
		document.getElementById('owner_logo').style.backgroundImage = null;
	}
}

function loadBg(num) {
	var container = document.getElementById('valota_app_container');
	if (_applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].type === 'image') {
		container.style.backgroundColor = _applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].color;
		container.style.backgroundImage = 'url(' + _applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].url + ')';
		container.style.backgroundSize = 'cover';
	} else {
		// color
		container.style.backgroundColor = _applicationData[num].palette.palette.backgrounds[_VALOTA_CUR_BG].color;
		container.style.backgroundImage = '';
		container.style.backgroundSize = '';
	}
}

function preloadBgs(num) {
	if (!_applicationData[num].hasOwnProperty('bgs')) {
		_applicationData[num].bgs = [];
	}
	var i;
	for (i = 0; i < _applicationData[num].bgs.length; i++) {
		_applicationData[num].bgs[i].inUse = false;
	}
	for (i = 0; i < _applicationData[num].palette.palette.backgrounds.length; i++) {
		if (_applicationData[num].palette.palette.backgrounds[i].type === 'image') {
			var found = false;
			for (var j = 0; j < _applicationData[num].bgs.length; j++) {
				if (_applicationData[num].bgs[j].url === _applicationData[num].palette.palette.backgrounds[i].url) {
					_applicationData[num].bgs[j].inUse = true;
					found = true;
				}
			}
			if (!found) {
				var img = new Image();
				img.src = _applicationData[num].palette.palette.backgrounds[i].url;
				console.log("preloading " + _applicationData[num].palette.palette.backgrounds[i].url);
				_applicationData[num].bgs.push({
					url: _applicationData[num].palette.palette.backgrounds[i].url,
					inUse: true,
					img: img
				});
			}
		}
	}
	for (i = _applicationData[num].bgs.length - 1; i >= 0; i--) {
		if (!_applicationData[num].bgs[i].inUse) {
			_applicationData[num].bgs.splice(i, 1);
		}
	}
}

function generateLocalFlow() {
	_flow = {config: {type: 'static', uuid: 'local'}, contents: []};
	var updateId = "";
	for (var i = 0; i < _applicationData.length; i++) {
		updateId += _applicationData[i].source.changeID;
		if (!_applicationData[i].isOverlay) {
			_flow.contents.push({type: 1, volume: 1, uuid: _applicationData[i].uuid});
		}
	}
	_flow.config.updateId = updateId;
	setLocal('flow', _flow);
}

var _RUN_TIMEOUT;

function runStories() {

	// if we don't have a current story then wait for the first one to be ready
	if (typeof _applicationData !== 'undefined') {
		if (_flow) {
			if (!_FLOW_HANDLER) {
				reFlow();
			}

		} else {
			generateLocalFlow();
			reFlow();
		}
	}
}

// check if function exists and then run it
function runFunction(func, appId, args) {

	try {
		if (typeof func === 'function') {
			if (typeof args !== 'undefined') {
				return func(args);
			}
			return func();
		}
		console.error("[Engine] " + appId + " trying to run non-existent function " + typeof func);
	} catch (err) {
		errorOn(appId);
		logError(func.name + ' caused error: ' + err.toString(), appId);
	}

	return false;

}


var _APP_WINDOWS = ["working", "valota_container", "waiting_for_content"];
var _CURRENT_WINDOW = "working";

function showWindow(id) {
	let change = id !== _CURRENT_WINDOW;

	if (typeof ValotaAndroid !== 'undefined') {
		if (id === 'valota_container') {
			ValotaAndroid.contentRunning();
		} else if (id === "enter_pin") {
			ValotaAndroid.contentNotRunning();
		}
	}
	for (var i = 0; i < _APP_WINDOWS.length; ++i) {
		var targ = document.getElementById(_APP_WINDOWS[i]);
		if (_APP_WINDOWS[i] === id) {
			targ.style.display = 'block';
			if (id === 'enter_pin') {
				targ.style.display = 'grid';
			}
			_CURRENT_WINDOW = id;
		} else {

			targ.style.display = 'none';
		}
	}

	if (change) {

		if (_DELAYED_RELOAD) {
			clearTimeout(_DELAYED_RELOAD);
			_DELAYED_RELOAD = null;
		}

		if (['enter_pin', 'claim_display'].indexOf(id) !== -1) {
			//start delayed reload
			_DELAYED_RELOAD = window.setTimeout(reloadDisplay, _DELAYED_RELOAD_TIME);

		}
	}


}

var _DELAYED_RELOAD = null;
var _DELAYED_RELOAD_TIME = 10 * 60 * 1000;

function reloadDisplay() {
	location.reload(true);
}


var _STARTING_PLAY = false;

function playOverlay(num) {
	if (!_applicationData[num].isOverlay || _applicationData[num].uuid !== _overlayApp) {
		console.error('[Engine] playing wrong overlay app', num);
		return;
	}
	if (typeof _VALOTA_DEVICE !== 'undefined') {
		ValotaEngine.ChromeOS.playOverlayApp(_applicationData[num]);
		return;
	}
	// return if no container
	if (!_applicationData[num] || !_applicationData[num].container) {
		return;
	}
	$('#overlay').show();
	runFunction(_applicationData[num].container.contentWindow.ValotaShow, _applicationData[num].uuid);
	_applicationData[num].container.className = 'active';
}
function stopOverlay(num) {
	if (!_applicationData[num].isOverlay || _applicationData[num].uuid !== _overlayApp) {
		console.error('[Engine] stopping wrong overlay app', num);
		return;
	}
	if (typeof _VALOTA_DEVICE !== 'undefined') {
		ValotaEngine.ChromeOS.stopOverlayApp();
		return;
	}
	// return if no container
	if (!_applicationData[num] || !_applicationData[num].container) {
		return;
	}
	runFunction(_applicationData[num].container.contentWindow.ValotaHide, _applicationData[num].uuid);
	_applicationData[num].container.className = 'inactive';
	$('#overlay').hide();

}

function playStory(num) {
	_STARTING_PLAY = true;
	// no point switching to oneself
	if (num === currentStory) {
		//same story
		// hide next content
		hideNextContent();
		// get app's name if it's in different flow
		document.getElementById('now_playing').innerHTML = getCurrentAppName(num);
		// call cycle
		console.log('[ENGINE] Cycle called from playStory() when playing self');
		runFunction(_applicationData[currentStory].container.contentWindow.ValotaCycle, _applicationData[currentStory].uuid);
		return;
	}

	// let's ignore transition times from flow run times
	FLOW_PAUSED = true;
	// hide old

	$('#valota_app_container').toggleClass('show', false);

	var transferTime = 0;
	//hide header after transition time
	if (currentStory !== -1) {
		// we don't have current story now
		/*if (_SHOW_TITLE) {
		 transferTime = _TRANSITION_TIME;
		 }*/
		setTimeout(function () {

			document.getElementById('now_playing').className = 'scarce';

			// return if no container
			if (!_applicationData[currentStory] || !_applicationData[currentStory].container) {
				return;
			}

			// inactive class and hide callback
			_applicationData[currentStory].container.className = 'inactive';
			var next = runFunction(_applicationData[currentStory].container.contentWindow.ValotaHide, _applicationData[currentStory].uuid);
			ValotaEngine.Video.stopVideosByApp(_applicationData[currentStory].uuid);
			if (isset(next)) {
				_applicationData[currentStory].playNext = next;
			}


		}, transferTime);

	}
	hideNextContent();

	if (_SHOW_TITLE) {
		transferTime += _TRANSITION_TIME;
	}
	setTimeout(function () {
// show header
		document.getElementById('now_playing').className = '';

		document.getElementById('now_playing').innerHTML = getCurrentAppName(num);

		setTimeout(function () {


			// active class and show callback
			for (var i = 0; i < _applicationData.length; ++i) {
				if (!_applicationData[i].container) {
					continue;
				}
				if (i !== num) {
					_applicationData[i].container.className = 'inactive';
				} else {
					_applicationData[i].container.className = 'active';
				}
			}

			// return if no container
			if (!_applicationData[num] || !_applicationData[num].container) {
				return;
			}

			if (typeof ValotaAndroid !== 'undefined') {
				console.log("[Engine Android] heavycontent: " + (typeof _applicationData[num].container.contentWindow.ValotaHeavyContent));
				if (typeof _applicationData[num].container.contentWindow.ValotaHeavyContent === 'function' && _applicationData[num].container.contentWindow.ValotaHeavyContent()) {
					ValotaAndroid.setHWAcceleration(false);
				} else {
					ValotaAndroid.setHWAcceleration(true);
				}
			}
			loadBg(num);
			runFunction(_applicationData[num].container.contentWindow.ValotaShow, _applicationData[num].uuid, _applicationData[num].playNext);
			_applicationData[num].playNext = null;
			$('#valota_app_container').toggleClass('show', true);

			currentStory = num;
			FLOW_PAUSED = false;


		}, _TRANSITION_TIME);


	}, transferTime);

	showWindow('valota_container');
}


function getCurrentAppName(num) {
	if (_FLOW_HANDLER) {
		return _FLOW_HANDLER.getName();
	} else if (isset(_applicationData[num])) {
		return _applicationData[num].name;
	}

	return _('Untitled');

}

/**
 * Checks whether the platforms supports required technologies
 *
 * @returns {Boolean}
 */
function checkCompatibility() {

	if (localStorage === null || typeof localStorage === 'undefined') {
		return false;
	}

	return true;
}

function getStoryLoc(uuid) {

	if (typeof _applicationData === "undefined") {
		console.error("[Engine] _applicationData was undefined and couldn't find " + uuid + " in getStoryLoc()");
		return -1;
	}
	for (var i = 0; i < _applicationData.length; ++i) {
		if (_applicationData[i].uuid === uuid) {
			return i;
		}
	}
	console.error("[Engine] Couldn't find " + uuid + " in getStoryLoc()");
	return -1;

}

function getLoadingDiv(cls) {

	var div = document.createElement('div');
	div.className = 'inline_loader_logo active' + (typeof cls === 'string' ? ' ' + cls : '');
	return div;
}

function setClocks() {
	var dateNow, element, newTime, offset;
	var curTime = Date.now() + _TIME_CHECK;


	var waiting_clock_set = false;
	if (_SHOW_TITLE_CLOCK) {
		offset = typeof _SHOW_TITLE_CLOCK.offset !== 'undefined' ? _SHOW_TITLE_CLOCK.offset : 0;
		dateNow = new Date(curTime + (offset * 1000));
		element = document.getElementById('title_clock');
		newTime = formatTime(dateNow, _SHOW_TITLE_CLOCK.am);
		if (element.innerHTML !== newTime) {
			element.innerHTML = newTime;
		}

		element = document.getElementById('waiting_clock');
		if (element.innerHTML !== newTime) {
			waiting_clock_set = true;
			element.innerHTML = newTime;
		}
	}

	if (_SHOW_STATS_CLOCK) {
		offset = typeof _SHOW_STATS_CLOCK.offset !== 'undefined' ? _SHOW_STATS_CLOCK.offset : 0;
		dateNow = new Date(curTime + (offset * 1000));
		element = document.getElementById('stats_clock');
		newTime = formatTime(dateNow, _SHOW_STATS_CLOCK.am);
		if (element.innerHTML !== newTime) {
			element.innerHTML = newTime;
		}

		if (!waiting_clock_set) {
			element = document.getElementById('waiting_clock');
			if (element.innerHTML !== newTime) {
				element.innerHTML = newTime;
			}
		}
	}

}

function addNull(val) {
	return val < 10 ? "0" + val : val;
}

function formatTime(dateObj, am) {
	if (!am) {
		return addNull(dateObj.getUTCHours()) + ':' + addNull(dateObj.getUTCMinutes());
	}

	var hours = dateObj.getUTCHours();
	var unit = 'a.m.';
	if (hours === 0) {
		hours = 12;
	} else if (hours > 12) {
		hours -= 12;
		unit = 'p.m.';
	}
	return hours + ':' + addNull(dateObj.getUTCMinutes()) + ' ' + unit;

}

var _CLOCK_TIMEOUT = false;

function engineLayout() {
	var header = document.getElementById('header_container');
	if (_SHOW_TITLE) {
		header.style.display = 'block';
		$('#valota_app_container').toggleClass('noTitle', false);


	} else {
		header.style.display = 'none';
		$('#valota_app_container').toggleClass('noTitle', true);
	}

	if (_SHOW_LOGO) {
		$('#valota_app_container').removeClass('noLogo');
	} else {
		$('#valota_app_container').addClass('noLogo');
	}

	if (_SHOW_STATS) {
		document.getElementById('display_stats').style.display = 'block';
		$('#valota_app_container').toggleClass('stats', true);
	} else {
		document.getElementById('display_stats').style.display = 'none';
		$('#valota_app_container').toggleClass('stats', false);
	}

	if (_SHOW_NEXT) {
		$('#up_next').toggleClass('hidden', false);
	} else {
		$('#up_next').toggleClass('hidden', true);
	}

	var _CLOCK_IS_ON = false;
	if (_SHOW_TITLE && _SHOW_TITLE_CLOCK) {
		$('#header_container').toggleClass('clock', true);
		_CLOCK_IS_ON = true;
	} else {
		$('#header_container').toggleClass('clock', false);
	}

	if (_SHOW_STATS && _SHOW_STATS_CLOCK) {
		$('#display_stats').toggleClass('clock', true);
		_CLOCK_IS_ON = true;
	} else {
		$('#display_stats').toggleClass('clock', false);
	}

	if (_SHOW_TITLE_CLOCK || _SHOW_STATS_CLOCK) {
		$('#waiting_clock').toggleClass('hidden', false);
	} else {
		$('#waiting_clock').toggleClass('hidden', true);
	}

	if (_CLOCK_IS_ON) {

		if (!_CLOCK_TIMEOUT) {
			_CLOCK_TIMEOUT = setInterval(setClocks, 10000);
		}
		setClocks();

	} else if (_CLOCK_TIMEOUT) {
		clearInterval(_CLOCK_TIMEOUT);
		_CLOCK_TIMEOUT = false;

	}


	document.getElementById('stats_display_name').innerHTML = _displayName;
	document.getElementById('stats_display_user').innerHTML = _displayUser ? ' [' + _displayUser + '] ' : '';
	if (_displayUser) {
		$('#stats_display_user').removeClass('hide');
	} else {
		$('#stats_display_user').addClass('hide');
	}
}

function formatSmallStats() {
	var stats = '<span class="bold">' + (_FETCH_SUCCESFULL + _FETCH_FAILS) + '</span>';
	stats += ' fetches with a success <span class="bold">' + Math.round((_FETCH_SUCCESFULL / (_FETCH_SUCCESFULL + _FETCH_FAILS)) * 10000) / 100 + '%</span>';

	document.getElementById('stats_little_stats').innerHTML = stats;

	var fails = document.getElementById('last_fetches_failed');
	if (_FETCH_FAILS_IN_ROW) {
		var text = '<span class="bold">' + _FETCH_FAILS_IN_ROW + '</span> failed in a row';
		fails.innerHTML = text;
		fails.className = '';
	} else {
		fails.className = 'hide';
	}
}

function setRunTime(num, s) {
	var tested = 10;
	if (parseInt(s)) {
		tested = parseInt(s);
		if (tested > _MAX_RUN_TIME) {
			tested = _MAX_RUN_TIME;
		}
	} else {
		logError("garbage run time " + s, _applicationData[num].uuid, "error");
	}
	_applicationData[num].runTime = tested;
}

function setCycleTime(num, s) {
	var tested = 10;
	if (parseInt(s)) {
		tested = parseInt(s);
		tested = Math.max(Math.min(tested, _MAX_CYCLE_TIME), 1);
	} else {
		logError("garbage cycle time " + s, _applicationData[num].uuid, "error");
	}
	if (tested !== s) {
		console.warn('[Engine] Cycle time set to ' + tested + ' instead of ' + s);
	}

	_applicationData[num].cycleTime = tested;

	/*if(_FLOW_HANDLER) {
	 var myFlowContent = _FLOW_HANDLER.getContent(_applicationData[num].uuid);
	 console.log("[Engine] cycletime", s, _applicationData[num].runTime, myFlowContent.hasRun);
	 if (_applicationData[num].runTime < myFlowContent.hasRun + s) {
	 _applicationData[num].runTime = myFlowContent.hasRun + s;
	 console.log("[Engine] runtime increase to", _applicationData[num].runTime);
	 }
	 } else {
	 if (_applicationData[num].runTime <  s) {
	 _applicationData[num].runTime = s;
	 console.log("[Engine] runtime decrease to", _applicationData[num].runTime);
	 }
	 }*/


}

function setPreferredCycles(num, s) {
	var tested = 1;
	if (parseInt(s)) {
		tested = Math.max(Math.min(parseInt(s), _MAX_CYCLES), 1);
		if (tested !== s) {
			console.warn('[Engine] Preferred cycles set to ' + tested + ' instead of ' + s);
		}
	} else {
		logError("garbage preferred cycles " + s, _applicationData[num].uuid, "error");
	}
	_applicationData[num].preferredCycles = tested;

}

function showNextContent(name) {
	var next = $('#up_next');
	next.toggleClass('visible', true);
	next.html(name);
}

function hideNextContent() {
	var next = $('#up_next');
	next.toggleClass('visible', false);
}


function gat(agoSeconds, format) {
	if (isNaN(agoSeconds)) {
		return '';
	}
	if (typeof format === 'undefined')
		format = 'original';

	if (format === 'original') {
		if (agoSeconds < 60) {
			return _("under a minute");
		} else if (agoSeconds < 3600) {
			agoSeconds = Math.round(agoSeconds / 60);
			if (agoSeconds === 1)
				return _("a minute");
			else
				return _("X minutes".replace("X", agoSeconds));
		} else if (agoSeconds < 86400) {
			agoSeconds = Math.round(agoSeconds / 3600);
			if (agoSeconds === 1)
				return _("an hour");
			else
				return _("X hours".replace("X", agoSeconds));
		} else if (agoSeconds < 604800) {
			agoSeconds = Math.round(agoSeconds / 86400);
			if (agoSeconds === 1)
				return _("a day");
			else
				return _("X days".replace("X", agoSeconds));
		} else if (agoSeconds < 2419200) {
			agoSeconds = Math.round(agoSeconds / 604800);
			if (agoSeconds === 1)
				return _("a week");
			else
				return _("X weeks".replace("X", agoSeconds));
		} else if (agoSeconds < 29030400) {
			agoSeconds = Math.round(agoSeconds / 2419200);
			if (agoSeconds === 1)
				return _("a month");
			else
				return _("X months".replace("X", agoSeconds));
		} else {
			agoSeconds = Math.round(agoSeconds / 29030400);
			if (agoSeconds === 1)
				return _("a year");
			else
				return _("X years".replace("X", agoSeconds));
		}
	} else if (format === 'short') {
		if (agoSeconds < 60) {
			return _("now");
		} else if (agoSeconds < 3600) {
			agoSeconds = Math.round(agoSeconds / 60);
			return _("Xm".replace("X", agoSeconds));
		} else if (agoSeconds < 86400) {
			agoSeconds = Math.round(agoSeconds / 3600);
			return _("Xh".replace("X", agoSeconds));
		} else if (agoSeconds < 604800) {
			agoSeconds = Math.round(agoSeconds / 86400);
			return _("Xd".replace("X", agoSeconds));
		} else if (agoSeconds < 2419200) {
			agoSeconds = Math.round(agoSeconds / 604800);
			return _("Xw".replace("X", agoSeconds));
		} else if (agoSeconds < 29030400) {
			agoSeconds = Math.round(agoSeconds / 2419200);
			return _("XM".replace("X", agoSeconds));
		} else {
			agoSeconds = Math.round(agoSeconds / 29030400);
			return _("XY".replace("X", agoSeconds));
		}
	}
}

function newContentOnApp(uuid, id) {
	var num = getStoryLoc(uuid);
	if (num === -1) {
		return;
	}

	_applicationData[num].latestContent = Date.now();

	if (isset(id)) {
		_applicationData[num].playNext = id;
	}

	if (_FLOW_HANDLER) {
		_FLOW_HANDLER.newContentOnContent(uuid, _applicationData[num].latestContent);
	}
}

function registerStore(readyCallback) {
	_READY_CALLBACK = readyCallback;
}

var LAST_RED_BUTTON_EPOCH_MS = 0;

function redButton() {
	if (Date.now() - LAST_RED_BUTTON_EPOCH_MS < 10000) {
		//max one panic button every ten seconds
		return;
	}
	document.getElementById('success_sound').play();
	document.getElementById('notification').innerHTML = 'Thank you. Error has been logged.';
	document.getElementById('notification').className = 'active';
	setTimeout(function () {
		document.getElementById('notification').className += ' checkout';
	}, 100);

	setTimeout(function () {
		document.getElementById('notification').className = '';
	}, 6000);

	var app_uuid = false;
	if (currentStory !== -1) {
		app_uuid = _applicationData[currentStory].uuid;
	}

	var window_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	var window_height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;


	var error_uuid = makeId(18);
	var request = {
		action: "valota_api/displays/red_button",
		display_uuid: _displayID,
		running_app_uuid: app_uuid,
		errors: JSON.stringify(_PREVIOUS_100_ERRORS),
		application_data: JSON.stringify(_applicationData),
		flow: JSON.stringify(_flow),
		error_uuid: error_uuid,
		user_agent: navigator.userAgent,
		window_width: window_width,
		window_height: window_height
	};

	LAST_RED_BUTTON_EPOCH_MS = Date.now();

	sendScreenshot(app_uuid, error_uuid);

	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,
		success: function () {

		},
		error: function (xhr, textStatus, errorThrown) {
			//TODO: save for later if fails
		},
		dataType: "json"
	});
}

function takeScreenshot() {

	var app_uuid = false;
	if (currentStory !== -1) {
		app_uuid = _applicationData[currentStory].uuid;
	}

	sendScreenshot(app_uuid, 0);

}

function sendScreenshot(app_uuid, error_uuid) {

	if (typeof _VALOTA_DEVICE !== 'undefined') {
		if (typeof ValotaEngine.ChromeOS.getScreenshot === 'function') {
			ValotaEngine.ChromeOS.getScreenshot(getScreenshot);
		}
	} else if (typeof _VALOTA_EXTENSION !== 'undefined') {
		if (typeof ValotaEngine.ChromeExtension.getScreenshot === 'function') {
			ValotaEngine.ChromeExtension.getScreenshot(getScreenshot);
		}
	} else if (typeof _VALOTA_TIZEN !== 'undefined') {
		if (typeof ValotaEngine.Tizen.getScreenshot === 'function') {
			ValotaEngine.Tizen.getScreenshot(getScreenshot);
		}
	} else if (typeof ValotaAndroid !== 'undefined') {
		if (typeof ValotaEngine.Android.getScreenshot === 'function') {
			ValotaEngine.Android.getScreenshot(getScreenshot);
		}
	} else {
		if (typeof ValotaEngine.FileSystem.getScreenshot === 'function') {
			ValotaEngine.FileSystem.getScreenshot(getScreenshot);
		}
	}


	function getScreenshot(base64) {

		if (!base64) {
			return;
		}

		var boundingBox = JSON.stringify(document.getElementById('valota_app_container').getBoundingClientRect());
		var request = {
			action: "valota_api/displays/screenshot",
			display_uuid: _displayID,
			app_uuid: app_uuid,
			error_uuid: error_uuid,
			app_area: boundingBox,
			imageBase64: base64
		};

		$.ajax({
			type: "POST",
			url: _VALOTA_REST,
			data: request,
			success: function () {

			},
			error: function (xhr, textStatus, errorThrown) {
				//TODO: save for later if fails
			},
			dataType: "json"
		});


	}

}


function handleKey(event) {

	switch (event.code) {
		case 'Digit1':
			if (event.shiftKey) {
				redButton();
			}
			break;

		case 'Digit5':
			if (event.shiftKey) {
				takeScreenshot();
			}
			break;
	}

}

window.onkeydown = function (event) {
	handleKey(event);
};

function shouldSendScreenshotData() {
	// determine if engine should send screenshots
	return false;

}

function reloadApp() {
	if (typeof _VALOTA_DEVICE !== 'undefined') {
		if (typeof ValotaEngine.ChromeOS.reloadApp === 'function') {
			ValotaEngine.ChromeOS.reloadApp();
		}
	} else if (typeof _VALOTA_EXTENSION !== 'undefined') {
		if (typeof ValotaEngine.ChromeExtension.reloadApp === 'function') {
			ValotaEngine.ChromeExtension.reloadApp();
		}
	} else if (typeof _VALOTA_TIZEN !== 'undefined') {
		if (typeof ValotaEngine.Tizen.reloadApp === 'function') {
			ValotaEngine.Tizen.reloadApp();
		}
	} else if (typeof ValotaAndroid !== 'undefined') {
		if (typeof ValotaEngine.Android.reloadApp === 'function') {
			ValotaEngine.Android.reloadApp();
		}
	} else {
		if (typeof ValotaEngine.FileSystem.reloadApp === 'function') {
			ValotaEngine.FileSystem.reloadApp();
		}
	}
}

window.addEventListener("message", signedin);

function signedin(event) {
	if (!_SSO_URL.startsWith(event.origin) || _SSO_TYPE !== 'azure') {
		return; // something fishy
	}
	_LOGGED_IN = true;
	_SSO_AZURE.oid = event.data.oid;
	_SSO_AZURE.idp = event.data.idp;
	_SSO_AZURE.session = event.data.session;
	fetchData();
}

function notLoggedIn() {
	if (!_LOGGED_IN) {
		var div = document.getElementById('waiting_info');
		if (_SSO_TYPE === "azure") {
			if (_SSO_USE_BUTTON) {
				var frame = document.getElementById('valota_signon_frame');
				frame.src = _SSO_URL.replace(/\?.*$/, '?wait');
				div.innerHTML = "<a href=\"" + _SSO_URL + "\" target='_blank' rel='opener'><button type=\"button\" class=\"btn mi waves-effect\" data-icon=\"open_in_new\">" + _("Login to your intranet") + "</button></a>";
			} else {
				div.innerHTML = _("Log yourself into your Intranet to see the content");

			}
		} else if (_SSO_TYPE === "azure2") {
			div.innerHTML = _("Content not authorized");
		} else {
			div.innerHTML = _("Not logged in.");
		}
	}
}

function saveLog() {
	if (_PREVIEW) {
		return;
	}
	if (!_PLAY_HISTORY_ENABLED) {
		return;
	}

	if (_FLOW_HANDLER) {

		_UNSENT_LOGS.push(_FLOW_HANDLER.reportLog());

	}

	setLocal('play_history', JSON.stringify(_UNSENT_LOGS));


}